<template>
  <div align="center">
    <ProgressState :position="1" />
    <SelectedCard editLink="/" :upd="upd" />
    <el-tabs type="border-card" class="boxed">
      <el-tab-pane label="Konzentrate">
        <span v-for="dc in this.$DC" :key="dc.id">
          <el-tooltip placement="right" effect="light" style="margin-right:50px;" v-if="doDisplayCon(dc)">  
            <div slot="content">
              <img height="120" :src="getImgUrlDC(dc)">       
            </div> 
            <el-button v-if="!isIE()" class="conList" @click="performCli(dc)" :style="{backgroundColor:dc.color}">
              {{ dc.name }} - {{ dc.size }} ml<br />
              <el-input-number style="margin-top:20px;" class="el-input-number" v-if="dc.sel > 0" v-model="dc.sel" @change="handleChange" :min="0" :max="10"></el-input-number>            
            </el-button>
            <div v-else>              
              <el-button class="conList" @click="performCli(dc)" :style="{backgroundColor:dc.color}">
                {{ dc.name }} - {{ dc.size }} ml<br />
              </el-button>
              <el-input-number style="margin-top:0px;" class="el-input-number" v-if="dc.sel > 0" v-model="dc.sel" @change="handleChange" :min="0" :max="10"></el-input-number>
            </div>
          </el-tooltip>
        </span>

      </el-tab-pane>
    </el-tabs>
    <br />

    <el-button type="primary" @click="resetCon()" >Konzentrate zurücksetzen</el-button>
    <el-button type="primary" @click="$router.push('/step3')" >Weiter</el-button>
    <br />

 
  </div>
</template>




<script>
  import SelectedCard from '@/components/SelectedCard.vue'
  import ProgressState from '@/components/ProgressState.vue'

  export default {
    data() {
      return {
        upd: false,
        num: 1
      };
    },    
    components: {
      SelectedCard, ProgressState
    },
    methods: {
      doDisplayCon(con) {
        if (!con.combineWithElo && this.isEloSelected())
          return false
        else {
          return true
        }

      },
      isEloSelected()
      {
        var ret = false
        this.$DS.forEach((value, index) => {
          if ((value.sel > 0) && (value.cat === "SolCatEloMel") ) {
            ret = true;
          }
        })
        return ret;
      },
      performUpdate()
      {
        this.$forceUpdate();
        this.upd =! this.upd
      },  
      performCli(obj) {
        if (obj.sel < 10 && obj.sel < 1)  {
          obj.sel = obj.sel + 1;
        }
        this.performUpdate()
      },
      resetCon(){
        this.$DC.forEach((value, index) => {
          value.sel=0;
        });
        this.performUpdate()
      },
      isIE() {
        var ua = window.navigator.userAgent
        var msie1 = ua.indexOf('MSIE')
        var msie2 = ua.indexOf('rv:11.0) like Gecko')
        if ((msie1 > 0) || (msie2 > 0)) {
          return true
        } else {
          return false          
        }
      },
      handleChange(value, old_value) {
        if (value != old_value) {
          this.performUpdate()
        }
      },
      getImgUrlDC(obj)
        {
          return require('@/assets/img/packCon/' + obj.img)
        },      
    }
  }
</script>


 <style scoped>
  .conList {    
    cursor:pointer;
    color:white;
    width:95%;
    padding:10px;
    padding-top:15px;
    padding-bottom:15px;
    margin-left:10px;
    margin-right:10px;
    margin-bottom:5px;
    margin-top:5px;
    font-weight:600;
    font-size:15px;
  }
  .OLDconList {
    cursor:pointer; width:350px;color:#ffffff;background-color:#044171;padding:10px;padding-top:15px;padding-bottom:15px;margin-bottom:10px;margin-top:10px;border-color:#ddd;font-weight:600;font-size:13px;
  }
</style> 
